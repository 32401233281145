import React, { useMemo, useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
  Tab,
  Tabs,
  Menu,
  Slider,
  Box,
  Typography,
  OutlinedInput,
} from "@mui/material";
import { GridOn } from "@mui/icons-material";
import ContextMenu from "./ContextMenu";
import { getMultipleSheetsAsExcel } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { formatNumberWithComma, getDropdownValues } from "./utils";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import CustomLoadingOverlay from "./customLoadingOverlay.jsx";
import CustomNoRowsOverlay from "./customNoRowsOverlay.jsx";
import printDoc from "./utilities/pdfExport/printDoc";
import { Logos } from "./logos";
import moment from "moment";
import localStorage from 'react-secure-storage';
const Search = () => {
  const search1Columns = useMemo(() => {
    return [
      "businessSegment",
      "dosYear",
      "projectName",
      "projectId",
      "externalChartId",
      "lastName",
      "firstName",
      "barcode",
      "workItemNumber",
      "count",
      "gcmBusFuncRetrievalStatus",
      "gcmBusFuncStatus",
    ];
  }, []);

  const search2Columns = useMemo(() => {
    return [
      "businessSegment",
      "dosYear",
      "projectName",
      "projectId",
      "externalChartId",
      "lastName",
      "firstName",
      "barcode",
      "workItemNumber",
      "count",
      "loadStatus",
    ];
  }, []);

  const gridRef = useRef(null);
  const gridRef2 = useRef(null);
  const isFirstRender2 = useRef(true);

  const excelStyles = useMemo(() => {
    return [
      {
        id: "alignLeft",
        alignment: { horizontal: "Left", vertical: "Bottom" },
      },
      {
        id: "numberField",
        alignment: { horizontal: "Left", vertical: "Bottom" },
        numberFormat: { format: "#,###" },
      },
    ];
  }, []);

  const [state, setState] = useState({
    headerHeight: (window.innerWidth * 1.8) / 100,
    userType: localStorage.getItem("userType"),
    exportMenuOpen: false,
    anchorEl: null,
    tabIndex: 0,
    segmentOptions: [],
    dosYearOptions: [],
    projectNameOptions: [],
    projectIdOptions: [],
    clientOptions: [],
    vendorOptions: [],
    limit: 5000,
    segment: null,
    dosYear: null,
    projectName: [],
    projectId: [],
    vendorName:
      localStorage.getItem("userType") === "vendor"
        ? [localStorage.getItem("vendorName")]
        : [],
    clientName:
      localStorage.getItem("userType") !== "vendor"
        ? [localStorage.getItem("clientName")]
        : [],
    imageName: "",
    memberLastName: "",
    memberFirstName: "",
    alertInfo: null,
    alertWarning: null,
    gridApi: null,
    gridApi2: null,
    gridVer: 0,
    rowData: null,
    rowData2: null,
    colDefs2: null,
    colDefs1: null,
    columnDefs: [],
    columnDefs2: [],
    allColumnDefs: [
      {
        colId: "businessSegment",
        field: "businessSegment",
        headerName: "Segment",
        tooltipField: "businessSegment",
        sortable: true,
        resizable: true,
        filter: true,
        width: 110,
        hide: false,
        cellClass: "alignLeft",
      },
      {
        colId: "dosYear",
        field: "dosYear",
        headerName: "CV/DOS Year",
        sortable: true,
        resizable: true,
        filter: true,
        width: 125,
        hide: false,
        cellClass: "alignLeft",
      },
      {
        colId: "projectName",
        field: "projectName",
        headerName: "Project Name",
        tooltipField: "projectName",
        sortable: true,
        resizable: true,
        filter: true,
        width: 180,
        hide: false,
        cellClass: "alignLeft",
      },
      {
        colId: "projectId",
        field: "projectId",
        headerName: "Project ID",
        tooltipField: "projectId",
        sortable: true,
        resizable: true,
        filter: true,
        width: 110,
        hide: false,
        cellClass: "alignLeft",
      },
      {
        colId: "externalChartId",
        field: "externalChartId",
        headerName: "Image Name",
        tooltipField: "externalChartId",
        filter: "agTextColumnFilter",
        sortable: true,
        resizable: true,
        width: 420,
        hide: false,
        cellClass: "alignLeft",
      },

      {
        colId: "lastName",
        colId: "lastName",
        field: "member.lastName",
        headerName: "Member Last Name",
        tooltipField: "member.lastName",
        sortable: true,
        resizable: true,
        filter: true,
        width: 170,
        hide: false,
        cellClass: "alignLeft",
      },
      {
        colId: "firstName",
        colId: "firstName",
        field: "member.firstName",
        headerName: "Member First Name",
        tooltipField: "member.firstName",
        sortable: true,
        resizable: true,
        filter: true,
        width: 170,
        hide: false,
        cellClass: "alignLeft",
      },
      {
        colId: "barcode",
        field: "barcode",
        headerName: "Barcode",
        tooltipField: "barcode",
        sortable: true,
        resizable: true,
        filter: true,
        width: 260,
        hide: false,
        cellClass: "alignLeft",
      },
      {
        colId: "workItemNumber",
        field: "workItemNumber",
        headerName: "Work Item Number",
        tooltipField: "workItemNumber",
        sortable: true,
        resizable: true,
        filter: true,
        width: 165,
        hide: false,
        cellClass: "alignLeft",
      },
      {
        colId: "count",
        headerName: "Count of Encounter + DX Code",
        sortable: true,
        resizable: true,
        filter: true,
        hide: false,
        width: 240,
        cellClass: "numberField",
        valueGetter: (p) => {
          let encounterCount =
            p.data.mrm?.encounterCount === "null"
              ? 0
              : p.data.mrm?.encounterCount;
          let dxCodeCount =
            p.data.mrm?.dxCodeCount === "null" ? 0 : p.data.mrm?.dxCodeCount;
          return formatNumberWithComma(encounterCount + dxCodeCount);
        },
        cellRenderer: (p) => {
          let encounterCount =
            p.data.mrm?.encounterCount === "null"
              ? 0
              : p.data.mrm?.encounterCount;
          let dxCodeCount =
            p.data.mrm?.dxCodeCount === "null" ? 0 : p.data.mrm?.dxCodeCount;
          return formatNumberWithComma(encounterCount + dxCodeCount);
        },
      },
      {
        colId: "gcmBusFuncRetrievalStatus",
        field: "mrm.gcmBusFuncRetrievalStatus",
        headerName: "Retrieval Status",
        tooltipField: "mrm.gcmBusFuncRetrievalStatus",
        hide: false,
        sortable: true,
        resizable: true,
        filter: true,
        width: 145,
        cellClass: "alignLeft",
      },
      {
        colId: "gcmBusFuncStatus",
        field: "mrm.gcmBusFuncStatus",
        headerName: "Coding Status",
        tooltipField: "mrm.gcmBusFuncStatus",
        sortable: true,
        resizable: true,
        hide: false,
        filter: true,
        width: 135,
        cellClass: "alignLeft",
        valueGetter: (p) => {
          return p.data.mrm?.gcmBusFuncStatus === "null"
            ? ""
            : p.data.mrm?.gcmBusFuncStatus;
        },
        cellRenderer: (p) => {
          return p.data.mrm?.gcmBusFuncStatus === "null"
            ? ""
            : p.data.mrm?.gcmBusFuncStatus;
        },
      },
      {
        colId: "loadStatus",
        field: "loadStatus",
        headerName: "Load Status",
        tooltipField: "loadStatus",
        sortable: true,
        resizable: true,
        filter: true,
        hide: false,
        width: 120,
        cellClass: "alignLeft",
        valueGetter: (p) => {
          return p.data.loadStatus === "null" ? "" : p.data.loadStatus;
        },
        cellRenderer: (p) => {
          return p.data.loadStatus === "null" ? "" : p.data.loadStatus;
        },
      },
      {
        colId: "vendor",
        field: "chartSource",
        headerName: "Vendor Name",
        tooltipField: "chartSource",
        sortable: true,
        resizable: true,
        filter: true,
        hide: false,
        width: 140,
        cellClass: "alignLeft",
      },
      {
        colId: "client",
        field: "clientCode",
        headerName: "Client Name",
        tooltipField: "clientCode",
        sortable: true,
        resizable: true,
        filter: true,
        hide: false,
        width: 140,
        cellClass: "alignLeft",
      },
    ],
    frameworkComponents: {
      customLoadingOverlay: CustomLoadingOverlay,
      customNoRowsOverlay: CustomNoRowsOverlay,
    },
    loadingOverlayComponent: "customLoadingOverlay",
    noRowsOverlayComponent: "customNoRowsOverlay",
    exportItems: [
      {
        icon: <GridOn />,
        title: "Excel",
        items: [
          {
            title: "All",
            id: "all",
            type: "excel",
          },
          {
            title: "Image Detail",
            id: "imageDetail",
            type: "excel",
          },
          {
            title: "Chart Coding Adds Detail",
            id: "chartCodingAddsReporting",
            type: "excel",
          },
        ],
      },
    ],
  });

  const arrayMax = (arr) => {
    return arr.reduce(function (p, v) {
      return p > v ? p : v;
    });
  };

  const defaultColDef = useMemo(() => {
    return { 
        suppressHeaderFilterButton: true,
        sortable: true,
        resizable: true,
        filter: true,
        filterParams: {defaultToNothingSelected: true}
      };
  }, []);

  const onGridReady = (params) => {
    setState((p) => ({
      ...p,
      gridApi: params.api,
    }));
    window.onresize = () => {
      setState((p) => ({
        ...p,
        headerHeight: (window.innerWidth * 1.8) / 100,
      }));
    };
  };

  const onGridReady2 = (params) => {
    setState((p) => ({
      ...p,
      gridApi2: params.api,
    }));
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        let segmentOptions = await getDropdownValues("segment");
        let dosYearOptions = await getDropdownValues("dosYear");
        let projectIdOptions = await getDropdownValues("projectId");
        let projectNameOptions = await getDropdownValues("projectName");
        let vendorOptions = await getDropdownValues("vendor");
        let clientOptions = await getDropdownValues("client");
        let columnDefs = getColumnsDefs(0);
        let columnDefs2 = getColumnsDefs(1);
        console.log("columnDefs =>", columnDefs);
        console.log("columnDefs2 =>", columnDefs2);
        let dosYear = "";

        if (dosYearOptions) {
          const lastYear = moment().year() - 1;
          dosYear = lastYear.toString();
          // dosYearOptions.push('null');
        }

        await setState((p) => ({
          ...p,
          segmentOptions,
          dosYearOptions,
          projectIdOptions,
          projectNameOptions,
          vendorOptions,
          clientOptions,
          columnDefs,
          columnDefs2,
          dosYear: dosYear,
          projectId: ["All"],
          segment: "ACA",
          projectName: ["All"],
          memberLastName: "",
          gridVer: 1,
        }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchDropdownData();
  }, []);

  useEffect(() => {
    if (isFirstRender2.current) {
      isFirstRender2.current = false; // toggle flag after first render/mounting
      return;
    }
    getGridData();
  }, [state.gridVer]);

  useEffect(() => {
    const resetHeights = async () => {
      try {
        if (state.gridApi) {
          state.gridApi.resetRowHeights();
          state.gridApi.autoSizeAllColumns();
        }
        if (state.gridApi2) {
          state.gridApi2.resetRowHeights();
          state.gridApi2.autoSizeAllColumns();
        }
      } catch (err) {
        console.log(err);
      }
    };
    resetHeights();
  }, [state.headerHeight]);

  const onChangeDropdown = (name, val) => {
    console.log(val);
    let value = val;
    if (name === "projectName" || name === "projectId") {
      if (val.length > 1) {
        let lastItem = [...val].pop();
        if (lastItem === "All") {
          value = ["All"];
        } else {
          value = value.filter((val) => {
            return val != "All";
          });
        }
      }
    }
    setState((s) => ({ ...s, [name]: value }));
  };

  const getGridData = async () => {
    try {
      if (!state.segment) {
        setState((p) => ({
          ...p,
          alertWarning: "Segment is required.",
        }));
        return;
      }
      if (!state.dosYear) {
        setState((p) => ({
          ...p,
          alertWarning: "CV/DOS Year is required.",
        }));
        return;
      }

    //  state.gridApi.showLoadingOverlay();
    //  state.gridApi2.showLoadingOverlay();
      state.gridApi.setGridOption("loading", true);
      state.gridApi2.setGridOption("loading", true);
      let params = {
        ...(state.segment && { businessSegment: state.segment }),
        ...(state.projectName &&
          JSON.stringify(state.projectName) !== `["All"]` &&
          state.projectName.length > 0 && {
            projectName: state.projectName.filter((val) => {
              return val != "All";
            }),
          }),
        ...(state.projectId &&
          JSON.stringify(state.projectId) !== `["All"]` &&
          state.projectId.length > 0 && {
            projectId: state.projectId.filter((val) => {
              return val != "All";
            }),
          }),
        ...(state.clientName &&
          state.clientName.length > 0 && { clientCode: state.clientName }),
        ...(state.vendorName &&
          state.vendorName.length > 0 && { chartSource: state.vendorName }),
        ...(state.imageName && { externalChartId: state.imageName }),
        ...(state.memberLastName && {
          "member.lastName": state.memberLastName,
        }),
        ...(state.memberFirstName && {
          "member.firstName": state.memberFirstName,
        }),
        ...(state.limit &&
          state.limit !== "No Limit" && { limit: state.limit }),
      };
      params.dosYear = state.dosYear;

      let urls = [`${localStorage.getItem("apiUrl")}/getCvMain?`];

      let promises = urls.map(async (url) => {
        try {
          let r = await fetch(url, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
            }),
            body: JSON.stringify(params),
          });
          let rj = await r.json();
          return rj;
        } catch (e) {
          console.log(e);
        }
      });

      let results = await Promise.all(promises);
      console.log(results);

      if (results[0].rows.length > 0) {
        let resj = results[0];
        let rowData2 = [];
        // Loop rows for some processing
        for (let x = 0; x < resj.rows.length; x++) {
          // DSO-3973 - The value of "dosYear" is to be replaced with the "project.projectYear"
          // Set dosYear value to be projectYear
          resj.rows[x].dosYear = resj.rows[x].project?.projectYear ?? null;
          // Remove projectYear from the data
          if (resj.rows[x].project) delete resj.rows[x].project;

          // Get combinedCount, which is mrm.encounterCount + mrm.dxCodeCount
          if (resj.rows[x].mrm) {
            resj.rows[x].mrm.combinedCount =
              (resj.rows[x].mrm?.encounterCount ?? 0) +
              (resj.rows[x].mrm.dxCodeCount ?? 0);
          }

          // Check if mrm.edps is found in row
          if (resj.rows[x].mrm && resj.rows[x].mrm.edps) {
            if (resj.rows[x].mrm.edps.length > 0) {
              // We will clone a row if there are multiple objects in the mrm.edps array
              const edps = resj.rows[x].mrm.edps;
              // Loop the edps array
              for (let y = 0; y < edps.length; y++) {
                rowData2.push({
                  ...resj.rows[x],
                  loadStatus: edps[y].transactionStatus ?? "",
                });
              }
            } else {
              // mrm.edps not found? Just push the single row.
              rowData2.push({ ...resj.rows[x] });
            }
          } else {
            // mrm.edps not found? Just push the single row.
            rowData2.push({ ...resj.rows[x] });
          }
        }

      //  state.gridApi.hideOverlay();
      //  state.gridApi2.hideOverlay();
        state.gridApi.setGridOption("loading", false);
        state.gridApi2.setGridOption("loading", false);
        setState((p) => ({
          ...p,
          rowData: results[0].rows,
          rowData2: rowData2,
        }));
      } else {
        state.gridApi.setGridOption("loading", false);
        state.gridApi2.setGridOption("loading", false);
        state.gridApi.showNoRowsOverlay();
        state.gridApi2.showNoRowsOverlay();
        setState((p) => ({
          ...p,
          rowData: results[0].rows,
          rowData2: results[0].rows,
        }));
      }
    } catch (err) {
      console.log(err);
      setState((p) => ({
        ...p,
        alertWarning: "Failed to load grid data.",
      }));
    }
  };

  const getColumnsDefs = (tabIndex) => {
    let columnNames = [];
    if (tabIndex === 0) columnNames = search1Columns;
    if (tabIndex === 1) columnNames = search2Columns;

    if (localStorage.getItem("userType") === "vendor") {
      columnNames.push("client");
    } else {
      columnNames.push("vendor");
    }

    let columnDefs = [];

    console.log("columnNames =>", columnNames);

    for (let x = 0; x < state.allColumnDefs.length; x++) {
      let thisColumn = state.allColumnDefs[x];
      if (columnNames.includes(thisColumn.colId)) {
        columnDefs.push(thisColumn);
      }
    }

    console.log("columnDefs =>", columnDefs);

    return columnDefs;
  };

  const onCloseAlertWarning = () => {
    setState((p) => ({
      ...p,
      alertWarning: null,
    }));
  };

  const onCloseAlertInfo = () => {
    setState((p) => ({
      ...p,
      alertInfo: null,
    }));
  };

  const handleTextfieldChange = (e) => {
    setState((p) => ({
      ...p,
      [e.target.id]: e.target.value,
    }));
  };

  const handleClear = async (e) => {
    let dosYear = "";

    if (state.dosYearOptions) {
      const lastYear = moment().year() - 1;
      dosYear = lastYear.toString();
    }

    let columnDefs = await getColumnsDefs(0);
    let columnDefs2 = await getColumnsDefs(1);
    await setState((p) => ({
      ...p,
      dosYear: dosYear,
      projectId: ["All"],
      segment: "ACA",
      projectName: ["All"],
      vendorName:
        localStorage.getItem("userType") === "vendor"
          ? [localStorage.getItem("vendorName")]
          : [],
      clientName:
        localStorage.getItem("userType") !== "vendor"
          ? [localStorage.getItem("clientName")]
          : [],
      alertInfo: null,
      alertWarning: null,
      imageName: "",
      memberLastName: "",
      memberFirstName: "",
      limit: 5000,
      colDefs1: null,
      colDefs2: null,
      columnDefs,
      columnDefs2,
    }));

    await state.gridApi.resetColumnState();
    await state.gridApi2.resetColumnState();
  };

  const handleTabChange = async (e, val) => {
    // Display the correct columns in the grid
    let columnDefs = await getColumnsDefs(val);

    if (val === 1) {
      let defaultColumnDefs = await getColumnsDefs(0);
      let colState = state.gridApi.getColumnState();

      let colDefs = [];
      for (let x = 0; x < colState.length; x++) {
        for (let y = 0; y < defaultColumnDefs.length; y++) {
          if (colState[x].colId == defaultColumnDefs[y].colId) {
            let col = { ...defaultColumnDefs[y], ...colState[x] };
            colDefs.push(col);
          }
        }
      }

      await setState((s) => ({
        ...s,
        tabIndex: val,
        columnDefs2: state.colDefs2 ? state.colDefs2 : columnDefs,
        colDefs1: colDefs,
      }));
    } else {
      let defaultColumnDefs = await getColumnsDefs(1);
      let colState = state.gridApi2.getColumnState();

      let colDefs = [];
      for (let x = 0; x < colState.length; x++) {
        for (let y = 0; y < defaultColumnDefs.length; y++) {
          if (colState[x].colId == defaultColumnDefs[y].colId) {
            let col = { ...defaultColumnDefs[y], ...colState[x] };
            colDefs.push(col);
          }
        }
      }

      await setState((s) => ({
        ...s,
        tabIndex: val,
        columnDefs: state.colDefs1 ? state.colDefs1 : columnDefs,
        colDefs2: colDefs,
      }));
    }
  };

  const DateAndTime = () => {
    return new Date().toLocaleString();
  };

  const handleExport = async (file, type) => {
    console.log("file =>", file);
    console.log("type =>", type);

    let gridColumns1 = [
      "businessSegment",
      "dosYear",
      "projectName",
      "projectId",
      "externalChartId",
      "lastName",
      "firstName",
      "barcode",
      "workItemNumber",
      "count",
      "gcmBusFuncRetrievalStatus",
      "gcmBusFuncStatus",
      localStorage.getItem("userType") === "vendor" ? "client" : "vendor",
    ];

    let gridColumns2 = [
      "businessSegment",
      "dosYear",
      "projectName",
      "projectId",
      "externalChartId",
      "lastName",
      "firstName",
      "barcode",
      "workItemNumber",
      "count",
      "loadStatus",
      localStorage.getItem("userType") === "vendor" ? "client" : "vendor",
    ];

    let visibleColumns1 = state.colDefs1 ? state.colDefs1 : gridColumns1;
    let visibleColumns2 = state.colDefs2 ? state.colDefs2 : gridColumns2;

    console.log("visibleColumns1 => ", visibleColumns1);
    console.log("visibleColumns2 => ", visibleColumns2);

    let grid1Columns = [];
    let grid2Columns = [];
    if (state.tabIndex === 0) {
      visibleColumns1 = state.gridApi.getAllDisplayedColumns();
    } else {
      visibleColumns2 = state.gridApi2.getAllDisplayedColumns();
    }

    visibleColumns1.forEach((col) => {
      if (state.tabIndex === 0) {
        grid1Columns.push(col.colId);
      } else {
        if (state.colDefs1) {
          if (!col.hide) {
            grid1Columns.push(col.colId);
          }
        } else {
          grid1Columns.push(col);
        }
      }
    });

    visibleColumns2.forEach((col) => {
      if (state.tabIndex === 1) {
        grid2Columns.push(col.colId);
      } else {
        if (state.colDefs2) {
          if (!col.hide) {
            grid2Columns.push(col.colId);
          }
        } else {
          grid2Columns.push(col);
        }
      }
    });

    let fileName = "";
    if (file === "all") {
      fileName = "3PChartSummary";
    } else if (file === "imageDetail") {
      fileName = "ImageDetails";
    } else if (file === "chartCodingAddsReporting") {
      fileName = "ChartCodingAddsReporting";
    }

    if (type === "pdf") {
      let grids = [];
      if (file === "imageDetail" || file === "all") {
        grids.push({
          gridApi: state.gridApi,
          header: "Image Details",
          columnIds: grid1Columns,
        });
      }
      if (file === "chartCodingAddsReporting" || file === "all") {
        grids.push({
          gridApi: state.gridApi2,
          header: "Chart Coding Adds Reporting",
          columnIds: grid2Columns,
        });
      }
      printDoc(grids, `${fileName}_${moment().format("YYYYMMDDHHmm")}`);
    } else if (type === "excel") {
      let title = "";
      let fileTitle = "";
      if (file === "all") {
        title = "All Chart3P Summary Reports";
        fileTitle = "Chart3PSummary";
      } else if (file === "imageDetail") {
        title = "Image Detail";
        fileTitle = "ImageDetail";
      } else if (file === "chartCodingAddsReporting") {
        title = "Chart Coding Adds Reporting";
        fileTitle = "ChartCodingAddsReporting";
      }
      let header = [
        {
          cells: [
            // Row 1:
            {
              data: {
                type: "String",
                value: Logos.hdrOptum2, // see logos.js
              },
              mergeAcross: 1,
            },
          ],
        },
        { cells: [] }, // Row 2
        {
          cells: [
            // Row 3
            {
              styleId: "bigHeader",
              data: {
                type: "String",
                value: title,
              },
              mergeAcross: 1,
            },
          ],
        },
        {
          cells: [
            // Row 4
            {
              data: {
                type: "String",
                value: "Report Date: " + DateAndTime(),
              },
              mergeAcross: 2,
            },
          ],
        },
        { cells: [] }, // Row 5
      ];

      let footer = [
        { cells: [] },
        {
          cells: [
            {
              styleId: "smallFooter",
              data: {
                type: "String",
                value: "End of Report",
              },
            },
          ],
        },
      ];

      let spreadsheets = [];

      if (file === "imageDetail" || file === "all") {
        spreadsheets.push(
          state.gridApi.getSheetDataForExcel({
            sheetName: "Image Detail",
            prependContent: header,
            appendContent: footer,
            rowHeight: (params) => (params.rowIndex === 1 ? 70 : 25),
            addImageToCell: (rowIndex, col, value) => {
              if (rowIndex !== 1) {
                return;
              }
              return {
                image: {
                  id: "logo",
                  base64: value,
                  imageType: "png",
                  width: 197,
                  height: 67,
                  position: {
                    colSpan: 2,
                  },
                },
              };
            },
            columnKeys: grid1Columns,
          })
        );
      }

      if (file === "chartCodingAddsReporting" || file === "all") {
        spreadsheets.push(
          state.gridApi2.getSheetDataForExcel({
            sheetName: "Chart Coding Adds Reporting",
            prependContent: header,
            appendContent: footer,
            rowHeight: (params) => (params.rowIndex === 1 ? 70 : 25),
            addImageToCell: (rowIndex, col, value) => {
              if (rowIndex !== 1) {
                return;
              }
              return {
                image: {
                  id: "logo",
                  base64: value,
                  imageType: "png",
                  width: 197,
                  height: 67,
                  position: {
                    colSpan: 2,
                  },
                },
              };
            },
            columnKeys: grid2Columns,
          })
        );
      }

      // Adjust this to export multiple grids to Excel
      const blob = getMultipleSheetsAsExcel({
        author: "Optum Chart3P",
        data: spreadsheets,
        fileName: `${fileName}_${moment().format("YYYYMMDDHHmm")}.xlsx`,
        fontsize: 11,
      });

      await blobToFile({
        blob,
        fileName: `${fileName}_${moment().format("YYYYMMDDHHmm")}.xlsx`,
        fileType: "xlsx",
      });

      // await unsubscribe("handleExport", () => setExportFinished(true));
    }
  };

  const blobToFile = async (arg) => {
    const { blob, fileName, fileType } = arg;
    let options = {
      suggestedName: `${fileName}`,
    };
    await window
      .showSaveFilePicker(options)
      .then(async (fileHandle) => await fileHandle.createWritable())
      .then(async (stream) => {
        await stream.write(blob);
        await stream.close();
      })
      .then(() => {
        alert(`File ${fileName} has been successfully downloaded.`);
      })
      .catch((err) => {
        console.log(err);
        if (err.toString().includes("The user aborted a request")) {
          console.log("SaveFile aborted");
          alert("File download has been canceled.");
        } else {
          alert("There was an issue downloading the file, please try again.");
        }
      });
  };

  const handleOpenExportMenu = async (e) => {
    await setState((s) => ({
      ...s,
      anchorEl: e.currentTarget,
      exportMenuOpen: !s.exportMenuOpen,
    }));
  };

  const handleCloseExportMenu = async () => {
    await setState((s) => ({
      ...s,
      anchorEl: null,
      exportMenuOpen: false,
    }));
  };

  const handleSliderChange = (e, data) => {
    setState((s) => ({
      ...s,
      limit: data,
    }));
  };

  const handleLimitChange = (e) => {
    setState((s) => ({
      ...s,
      limit: e.target.value,
    }));
  };

  const handleBlur = () => {
    if (state.limit < 100) {
      setState((s) => ({
        ...s,
        limit: 100,
      }));
    } else if (state.limit > 40000) {
      setState((s) => ({
        ...s,
        limit: 40000,
      }));
    }
  };

  const gridOptions = {
    getRowHeight: (params) => {
      return (window.innerWidth * 1.5) / 100;
    },
  };
  const gridOptions2 = {
    getRowHeight: (params) => {
      return (window.innerWidth * 1.5) / 100;
    },
  };

  return (
    <Container
      fluid
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        width: "98%",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <img
        src={`${localStorage.getItem(
          "apiUrl"
        )}/getHit?c=Search&u=${localStorage.getItem("u")}
        &name=${localStorage.getItem("displayName")}
        &utype=${localStorage.getItem("userType")}
        &vendor=${localStorage.getItem("vendorName")}
        &client=${localStorage.getItem("clientName")}`}
        alt=""
        style={{ display: "none" }}
      />
      <Snackbar
        open={state.alertWarning !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={onCloseAlertWarning}
      >
        <Alert
          variant="filled"
          onClose={onCloseAlertWarning}
          severity="warning"
        >
          {state.alertWarning}
        </Alert>
      </Snackbar>

      <Snackbar
        open={state.alertInfo !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={onCloseAlertInfo}
      >
        <Alert variant="filled" onClose={onCloseAlertInfo} severity="info">
          {state.alertInfo}
        </Alert>
      </Snackbar>
      <Row>
        <Col xs={2} style={{ height: "91vh" }}>
          <div
            style={{
              padding: 5,
              border: "2px solid #e77721",
              borderRadius: 30,
              display: "flex",
              flexFlow: "column nowrap",
              height: "100%",
              // backgroundColor: "green",
            }}
          >
            <div
              style={{
                height: "3%",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  marginBottom: 8,
                  color: "#e77721",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Search Criteria
              </Typography>
            </div>
            <div
              style={{
                overflow: "auto",
                marginTop: "2%",
                flexGrow: "1",
                // backgroundColor: "blue",
              }}
            >
              <Autocomplete
                options={state.segmentOptions}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={state.segment}
                onChange={(e, val) => onChangeDropdown("segment", val)}
                noOptionsText={"Select Segment"}
                id="segment"
                style={{ width: "100%", marginTop: "2%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Segment" required />
                )}
              />
              <Autocomplete
                options={state.dosYearOptions}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={state.dosYear}
                onChange={(e, val) => onChangeDropdown("dosYear", val)}
                noOptionsText={"Select Year"}
                id="dosYear"
                style={{ width: "100%", marginTop: "10%" }}
                renderInput={(params) => (
                  <TextField {...params} label="CV/DOS Year" required />
                )}
              />
              <Autocomplete
                multiple
                options={["All", ...state.projectNameOptions]}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={state.projectName}
                onChange={(e, val) => onChangeDropdown("projectName", val)}
                noOptionsText={"Select Project Name"}
                id="projectName"
                style={{ width: "100%", marginTop: "10%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Project Name" />
                )}
              />
              <Autocomplete
                multiple
                options={["All", ...state.projectIdOptions]}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={state.projectId}
                onChange={(e, val) => onChangeDropdown("projectId", val)}
                noOptionsText={"Select Project ID"}
                id="projectId"
                style={{ width: "100%", marginTop: "10%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Project ID" />
                )}
              />
              {state.userType !== "vendor" ? (
                <Autocomplete
                  multiple
                  options={state.vendorOptions}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={state.vendorName}
                  onChange={(e, val) => onChangeDropdown("vendorName", val)}
                  style={{ width: "100%", marginTop: "10%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Vendor Name" />
                  )}
                />
              ) : (
                <Autocomplete
                  multiple
                  options={state.clientOptions}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={state.clientName}
                  onChange={(e, val) => onChangeDropdown("clientName", val)}
                  style={{ width: "100%", marginTop: "10%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Client Name" />
                  )}
                />
              )}
              <TextField
                id="imageName"
                label="Image Name"
                style={{ width: "100%", marginTop: "10%" }}
                onChange={handleTextfieldChange}
                value={state.imageName}
              />
              <TextField
                id="memberLastName"
                label="Member Last Name"
                style={{ width: "100%", marginTop: "10%" }}
                onChange={handleTextfieldChange}
                value={state.memberLastName}
              />
              <TextField
                id="memberFirstName"
                label="Member First Name"
                style={{ width: "100%", marginTop: "10%" }}
                onChange={handleTextfieldChange}
                value={state.memberFirstName}
              />
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginLeft: 0,
                // backgroundColor: "red",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginBottom: "10%",
                  display: "inline-block",
                  postion: "absolute",
                  top: 0,
                }}
              >
                <div style={{ width: "65%", float: "left", marginRight: "2%" }}>
                  <Slider
                    value={state.limit}
                    onChange={handleSliderChange}
                    min={100}
                    max={40000}
                    aria-labelledby="input-slider"
                    title="Limit the number of rows"
                    sx={{
                      color: "#e77721",
                    }}
                  />
                </div>
                <div style={{ width: "30%", float: "right" }}>
                  <OutlinedInput
                    variant="contained"
                    value={state.limit}
                    size="small"
                    onChange={handleLimitChange}
                    onBlur={handleBlur}
                    inputL
                    title="Limit the number of rows"
                    inputProps={{
                      step: 1000,
                      min: 100,
                      max: 40000,
                      style: {
                        padding: 0,
                        textAlign: "center",
                      },
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </div>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                title="Recalculate the Data"
                style={{
                  marginRight: "2%",
                }}
                onClick={getGridData}
              >
                Recalculate
              </Button>
              <Menu
                anchorEl={state.anchorEl}
                open={state.exportMenuOpen}
                onClose={handleCloseExportMenu}
                getcontentanchorel={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    minWidth: "300px",
                    maxHeight: "75px",
                  },
                }}
              >
                <ContextMenu
                  items={state.exportItems}
                  onExportClick={handleExport}
                ></ContextMenu>
              </Menu>
              <Button
                variant="contained"
                color="secondary"
                title="Export to Excel"
                style={{
                  marginLeft: "2%",
                }}
                onClick={handleOpenExportMenu}
              >
                Export
              </Button>
              <Button
                variant="contained"
                color="secondary"
                title="Reset the Search Criteria"
                style={{
                  margin: "2%",
                  backgroundColor: "white",
                  color: "black",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                onClick={handleClear}
              >
                Reset
              </Button>
            </div>
          </div>
        </Col>
        <Col xs={10} style={{ height: "85vh" }}>
          <Row className="m-0 p-0" style={{ height: "100%" }}>
            <Container
              fluid
              className="ag-theme-balham"
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <Tabs
                value={state.tabIndex}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                variant="fullWidth"
                sx={{
                  width: "100%",
                }}
              >
                <Tab
                  value={0}
                  label="Image Detail"
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  value={1}
                  label="Chart Coding Adds Detail"
                  sx={{ textTransform: "none" }}
                />
              </Tabs>
              <div
                style={{
                  height: "100%",
                  display: state.tabIndex == 0 ? "" : "none",
                }}
              >
                <AgGridReact
                  ref={gridRef}
                  onGridReady={onGridReady}
                //  rowSelection="single"
                  selection={{mode:"singleRow", checkboxes:false, enableClickSelection:true}}
                  pagination={true}
                  headerHeight={state.headerHeight}
                  paginationAutoPageSize={true}
                  autoSize={true}
                  columnDefs={state.columnDefs}
                  defaultColDef={defaultColDef} 
                  enableCellTextSelection={true}
                  rowData={state.rowData}
                  components={state.frameworkComponents}
                  loadingOverlayComponent={state.loadingOverlayComponent}
                  noRowsOverlayComponent={state.noRowsOverlayComponent}
                  animateRows={true}
                  gridOptions={gridOptions}
                  rowHeight={(window.innerWidth * 1.5) / 100}
                  excelStyles={excelStyles}
                />
              </div>
              <div
                style={{
                  height: "100%",
                  display: state.tabIndex == 1 ? "" : "none",
                }}
              >
                <AgGridReact
                  ref={gridRef2}
                  onGridReady={onGridReady2}
                //  rowSelection="single"
                  selection={{mode:"singleRow", checkboxes:false, enableClickSelection:true}}
                  pagination={true}
                  headerHeight={state.headerHeight}
                  paginationAutoPageSize={true}
                  autoSize={true}
                  columnDefs={state.columnDefs2}
                  defaultColDef={defaultColDef} 
                  enableCellTextSelection={true}
                  rowData={state.rowData2}
                  components={state.frameworkComponents}
                  loadingOverlayComponent={state.loadingOverlayComponent}
                  noRowsOverlayComponent={state.noRowsOverlayComponent}
                  animateRows={true}
                  gridOptions={gridOptions2}
                  rowHeight={(window.innerWidth * 1.5) / 100}
                  excelStyles={excelStyles}
                />
              </div>
            </Container>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Search;
