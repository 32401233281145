import React, { useState, useEffect, useRef, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Alert,
  Button,
  Menu,
  Snackbar,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import { GridOn, PictureAsPdf } from "@mui/icons-material";
import ContextMenu from "./ContextMenu";
import { AgGridReact } from "ag-grid-react";
import {
  exportMultipleSheetsAsExcel,
  getMultipleSheetsAsExcel,
} from "ag-grid-enterprise";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { formatNumberWithComma, getDropdownValues } from "./utils";
import CustomLoadingOverlay from "./customLoadingOverlay.jsx";
import CustomNoRowsOverlay from "./customNoRowsOverlay.jsx";
import printDoc from "./utilities/pdfExport/printDoc";
import { Logos } from "./logos";
import moment from "moment";
import localStorage from 'react-secure-storage';
import "./App.css";
const Dashboard = (props) => {
  const col1 = [
    "projectName",
    "projectId",
    "businessSegment",
    "dosYear",
    "vendor",
    "vendorCode",
    "client",
    "imagesExpected",
    "imagesReceived",
    "dxNotReceived",
    "dxReceived",
    "dxCanceled",
  ];
  const col2 = [
    "projectName",
    "projectId",
    "vendor",
    "client",
    "dxCodeCount",
    "successfulTransaction",
    "duplicatedTransaction",
    "erroredTransaction",
    "waitingToProcessTransaction",
  ];

  const excelStyles = useMemo(() => {
    return [
      {
        id: "alignLeft",
        alignment: { horizontal: "Left", vertical: "Bottom" },
      },
      {
        id: "numberField",
        alignment: { horizontal: "Left", vertical: "Bottom" },
        numberFormat: { format: "#,###" },
      },
    ];
  }, []);

  const isFirstRender = useRef(true);
  const gridRef1 = useRef(null);
  const gridRef2 = useRef(null);
  const gridRef3 = useRef(null);
  const divRef = useRef(null);
  const [state, setState] = useState({
    headerHeight: (window.innerWidth * 1.5) / 100,
    userType: localStorage.getItem("userType"),
    exportMenuOpen: false,
    anchorEl: null,
    segmentOptions: [],
    dosYearOptions: [],
    projectNameOptions: [],
    projectIdOptions: [],
    clientOptions: [],
    vendorOptions: [],
    segment: null,
    dosYear: null,
    projectName: [],
    projectId: [],
    vendorName:
      localStorage.getItem("userType") === "vendor"
        ? [localStorage.getItem("vendorName")]
        : [],
    clientName:
      localStorage.getItem("userType") !== "vendor"
        ? [localStorage.getItem("clientName")]
        : [],
    alertInfo: null,
    alertWarning: null,
    gridApi1: null,
    gridApi2: null,
    gridApi3: null,
    gridVer: 0,
    rowData1: null,
    columnDefs1: [],
    rowData2: null,
    columnDefs2: [
      {
        headerClass: "header-bolded",
        headerName: "Project Information",
        children: [
          {
            colId: "projectName",
            field: "projectName",
            headerName: "Project Name",
            tooltipField: "projectName",
            // sortable: true,
            resizable: true,
            filter: true,
            width: 200,
            headerClass: "header-bolded",
            cellClass: "alignLeft",
            // rowSpan: (params) => params.data.count,
            // cellClassRules: {
            //   "cell-span": true,
            // },
          },
          {
            colId: "projectId",
            field: "projectId",
            headerName: "Project ID",
            tooltipField: "projectId",
            // sortable: true,
            resizable: true,
            filter: true,
            width: 120,
            headerClass: "header-bolded",
            cellClass: "alignLeft",
            // cellClassRules: {
            //   "cell-span": true,
            // },
            // rowSpan: (params) => params.data.count,
          },
          {
            colId: "businessSegment",
            field: "businessSegment",
            headerName: "Segment",
            tooltipField: "businessSegment",
            width: 95,
            headerClass: "header-bolded",
            cellClass: "alignLeft",
            // sortable: true,
            resizable: true,
            filter: true,
            hide: true,
          },
          {
            colId: "dosYear",
            field: "dosYear",
            headerName: "CV/DOS Year",
            headerClass: "header-bolded",
            cellClass: "alignLeft",
            // sortable: true,
            resizable: true,
            filter: true,
            width: 90,
            hide: true,
          },
          {
            colId: "vendor",
            field: "vendor",
            headerName: "Vendor Name",
            tooltipField: "vendor",
            headerClass: "header-bolded",
            cellClass: "alignLeft",
            // sortable: true,
            resizable: true,
            filter: true,
            width: 130,
            hide: localStorage.getItem("userType") === "vendor" ? true : false,
          },
          {
            colId: "client",
            field: "client",
            headerName: "Client Name",
            tooltipField: "client",
            headerClass: "header-bolded",
            cellClass: "alignLeft",
            // sortable: true,
            resizable: true,
            filter: true,
            width: 130,
            hide: localStorage.getItem("userType") === "vendor" ? false : true,
          },
        ],
      },
      {
        headerName: "File Information",
        headerClass: "header-bolded",
        children: [
          {
            field: "fileName",
            headerName: "File Name",
            headerClass: "header-bolded",
            tooltipField: "fileName",
            // sortable: true,
            resizable: true,
            filter: true,
            width: 350,
            cellClass: "alignLeft",
          },
          {
            field: "fileDate",
            headerName: "Date",
            // sortable: true,
            resizable: true,
            filter: true,
            width: 150,
            cellClass: "alignLeft",
          },
        ],
      },
      {
        headerName: "Barcodes Count",
        headerClass: "header-bolded",
        children: [
          {
            field: "success",
            headerClass: "header-bolded",
            headerName: "Success",
            cellClass: "numberField",
            valueFormatter: (params) => formatNumberWithComma(params.value),
            // sortable: true,
            resizable: true,
            filter: true,
            width: 120,
          },
          {
            field: "fail",
            headerName: "Failed",
            headerClass: "header-bolded",
            cellClass: "numberField",
            valueFormatter: (params) => formatNumberWithComma(params.value),
            // sortable: true,
            resizable: true,
            filter: true,
            width: 120,
          },
        ],
      },
    ],
    rowData3: null,
    columnDefs3: [],
    frameworkComponents: {
      customLoadingOverlay: CustomLoadingOverlay,
      customNoRowsOverlay: CustomNoRowsOverlay,
    },
    loadingOverlayComponent: "customLoadingOverlay",
    noRowsOverlayComponent: "customNoRowsOverlay",
    exportItems: [
      {
        icon: <GridOn />,
        title: "Excel",
        items: [
          {
            title: "All",
            id: "all",
            type: "excel",
          },
          {
            title: "Process Summary Reporting",
            id: "processSummaryReporting",
            type: "excel",
          },
          {
            title: "Diagnosis Code Inbound File Details",
            id: "dxInboundFileDetails",
            type: "excel",
          },
          {
            title: "Chart Coding Adds Reporting",
            id: "chartCodingAddsReporting",
            type: "excel",
          },
        ],
      },
      {
        icon: <PictureAsPdf />,
        title: "PDF",
        items: [
          {
            title: "All",
            id: "all",
            type: "pdf",
          },
          {
            title: "Process Summary Reporting",
            id: "processSummaryReporting",
            type: "pdf",
          },
          {
            title: "Diagnosis Code Inbound File Details",
            id: "dxInboundFileDetails",
            type: "pdf",
          },
          {
            title: "Chart Coding Adds Reporting",
            id: "chartCodingAddsReporting",
            type: "pdf",
          },
        ],
      },
    ],
  });

  const onGridReady1 = (params) => {
    setState((p) => ({
      ...p,
      gridApi1: params.api,
    }));
  //  params.api.showLoadingOverlay();
    params.api.setGridOption("loading", true);
    window.onresize = () => {
      // console.log('yo this is your new height1 =>', `${Math.ceil((window.innerWidth * 1 / 100))}px`)
      setState((p) => ({
        ...p,
        headerHeight: (window.innerWidth * 1.5) / 100,
      }));
    };
  };

  const onGridReady2 = (params) => {
    setState((p) => ({
      ...p,
      gridApi2: params.api,
    }));
  //  params.api.showLoadingOverlay();
    params.api.setGridOption("loading", true);
  };

  const onGridReady3 = (params) => {
    setState((p) => ({
      ...p,
      gridApi3: params.api,
    }));
  //  params.api.showLoadingOverlay();
    params.api.setGridOption("loading", true);
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        // gridRef1.current.api.showLoadingOverlay();
        // gridRef2.current.api.showLoadingOverlay();
        // gridRef3.current.api.showLoadingOverlay();
        let segmentOptions = await getDropdownValues("segment");
        let dosYearOptions = await getDropdownValues("dosYear");
        let projectIdOptions = await getDropdownValues("projectId");
        let projectNameOptions = await getDropdownValues("projectName");
        let vendorOptions = await getDropdownValues("vendor");
        let clientOptions = await getDropdownValues("client");
        let dosYear = "";

        if (dosYearOptions) {
          const lastYear = moment().year() - 1;
          dosYear = lastYear.toString();
          // dosYearOptions.push('null');
        }

        await setState((p) => ({
          ...p,
          segmentOptions,
          dosYearOptions,
          projectIdOptions,
          projectNameOptions,
          vendorOptions,
          clientOptions,
          dosYear: dosYear,
          projectId: ["All"],
          segment: "ACA",
          projectName: ["All"],
          gridVer: 1,
        }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchDropdownData();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // toggle flag after first render/mounting
      return;
    }

    getGridData();
  }, [state.gridVer]);

  useEffect(() => {
    const resetHeights = async () => {
      try {
        if (state.gridApi1) {
          state.gridApi1.resetRowHeights();
          state.gridApi2.resetRowHeights();
          state.gridApi3.resetRowHeights();
          // state.gridApi1.autoSizeAllColumns();
          // state.gridApi2.autoSizeAllColumns();
          // state.gridApi3.autoSizeAllColumns();
        }
      } catch (err) {
        console.log(err);
      }
    };
    resetHeights();
  }, [state.headerHeight]);

  const getGridData = async () => {
    try {
      if (!state.segment) {
        setState((p) => ({
          ...p,
          alertWarning: "Segment is required.",
        }));
        return;
      }
      if (!state.dosYear) {
        setState((p) => ({
          ...p,
          alertWarning: "CV/DOS Year is required.",
        }));
        return;
      }

      let params = {
        ...(state.segment && { businessSegment: state.segment }),
        ...(state.projectName &&
          JSON.stringify(state.projectName) !== `["All"]` &&
          state.projectName.length > 0 && {
            projectName: state.projectName.filter((val) => {
              return val != "All";
            }),
          }),
        ...(state.projectId &&
          JSON.stringify(state.projectId) !== `["All"]` &&
          state.projectId.length > 0 && {
            projectId: state.projectId.filter((val) => {
              return val != "All";
            }),
          }),
        ...(state.clientName &&
          state.clientName.length > 0 && { client: state.clientName }),
        ...(state.vendorName &&
          state.vendorName.length > 0 && { vendor: state.vendorName }),
      };
      params.dosYear = state.dosYear;

    //  state.gridApi1.showLoadingOverlay();
    //  state.gridApi2.showLoadingOverlay();
    //  state.gridApi3.showLoadingOverlay();
      state.gridApi1.setGridOption("loading", true);
      state.gridApi2.setGridOption("loading", true);
      state.gridApi3.setGridOption("loading", true);
      let urls = [
        `${localStorage.getItem("apiUrl")}/getCvExtProcessSummary?`, //getCvDashImageSummary getCvMrmStatusView
        `${localStorage.getItem("apiUrl")}/getCvExtDxInboundFile?`, //getCvDashDxSummary
        `${localStorage.getItem("apiUrl")}/getCvChartCodingAdds?`,
      ];

      let promises = urls.map(async (url) => {
        try {
          let r = await fetch(url, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
            }),
            body: JSON.stringify(params),
          });
          let rj = await r.json();
          return rj;
        } catch (e) {
          console.log(e);
        }
      });

      // let test = [
      //   {
      //     projectId: "54675",
      //     projectName: "PAF 2023 UEI CV ONLY",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "DXINBOUND_INOVON_20230410111637.dat",
      //     fileDate: "2023-04-10",
      //     success: 9238,
      //     fail: 0,
      //     count: 2,
      //     max: true,
      //   },
      //   {
      //     projectId: "54675",
      //     projectName: "PAF 2023 UEI CV ONLY",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "",
      //     fileDate: "",
      //     success: 0,
      //     fail: 0,
      //     max: false,
      //     count: 1,
      //   },
      //   {
      //     projectId: "54675123",
      //     projectName: "PAF 2023 UEI CV ONLY",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "DXINBOUND_INOVON_20230410111637.dat",
      //     fileDate: "2023-04-10",
      //     success: 9238,
      //     fail: 0,
      //     count: 2,
      //     max: true,
      //   },
      //   {
      //     projectId: "546754321",
      //     projectName: "PAF 2023 UEI CV ONLY123",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "DXINBOUND_INOVON_20230410111637.dat",
      //     fileDate: "2023-04-10",
      //     success: 9238,
      //     fail: 0,
      //     count: 2,
      //     max: true,
      //   },
      //   {
      //     projectId: "54675321",
      //     projectName: "PAF 2023 UEI CV ONLY321",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "DXINBOUND_INOVON_20230410111637.dat",
      //     fileDate: "2023-04-10",
      //     success: 9238,
      //     fail: 0,
      //     count: 2,
      //     max: true,
      //   },
      //   {
      //     projectId: "54675321",
      //     projectName: "PAF 2023 UEI CV ONLY321",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "",
      //     fileDate: "",
      //     success: 0,
      //     fail: 0,
      //     max: false,
      //     count: 1,
      //   },
      //   {
      //     projectId: "54675",
      //     projectName: "PAF 2023 UEI CV ONLY",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "DXINBOUND_INOVON_20230410111637.dat",
      //     fileDate: "2023-04-10",
      //     success: 9238,
      //     fail: 0,
      //     count: 2,
      //     max: true,
      //   },
      //   {
      //     projectId: "54675976",
      //     projectName: "PAF 2023 UEI CV ONLY976",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "DXINBOUND_INOVON_20230410111637.dat",
      //     fileDate: "2023-04-10",
      //     success: 9238,
      //     fail: 0,
      //     count: 2,
      //     max: true,
      //   },
      //   {
      //     projectId: "54675123",
      //     projectName: "PAF 2023 UEI CV ONLY123",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "",
      //     fileDate: "",
      //     success: 0,
      //     fail: 0,
      //     max: false,
      //     count: 1,
      //   },
      //   {
      //     projectId: "54675123",
      //     projectName: "PAF 2023 UEI CV ONLY123",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "DXINBOUND_INOVON_20230410111637.dat",
      //     fileDate: "2023-04-10",
      //     success: 9238,
      //     fail: 0,
      //     count: 2,
      //     max: true,
      //   },
      //   {
      //     projectId: "54675976",
      //     projectName: "PAF 2023 UEI CV ONLY976",
      //     businessSegment: "ACA",
      //     dosYear: 2022,
      //     client: "UHC E-I",
      //     vendor: "INOVALON",
      //     fileName: "",
      //     fileDate: "",
      //     success: 0,
      //     fail: 0,
      //     max: false,
      //     count: 1,
      //   },
      // ];

      let results = await Promise.all(promises);

      // DSO-3973 - The value of "dosYear" is to be replaced with the "projectYear" value in all of 3 of the grid data sets.
      // So labels will read "dosYear", while the value is the "projectYear"
      for (let x = 0; x < results.length; x++) {
        for (let y = 0; y < results[x].rows.length; y++) {
          // Set dosYear value to be projectYear
          results[x].rows[y].dosYear = results[x].rows[y].projectYear ?? null;
          // Remove projectYear from the data (to prevent displaying extra lines in grids)
          if (results[x].rows[y].projectYear)
            delete results[x].rows[y].projectYear;
        }
      }

      console.log(results);
      let data1 = await transposeData(results[0]?.rows, 1);
      let data2 = await formatInboundData(results[1]?.rows);
      let data3 = await transposeData(results[2]?.rows, 2);
      console.log("data1 =>", data1);
      console.log("data2 =>", data2);
      console.log("data3 =>", data3);

      setState((p) => ({
        ...p,
        rowData1: data1,
        rowData2: data2,
        rowData3: data3,
      }));
      if (results[0]?.rows.length > 0) {
      //  state.gridApi1.hideOverlay();
      //  state.gridApi3.hideOverlay();
        state.gridApi1.setGridOption("loading", false);
        state.gridApi3.setGridOption("loading", false);
      } else {
        state.gridApi1.setGridOption("loading", false);
        state.gridApi3.setGridOption("loading", false);
        state.gridApi1.showNoRowsOverlay();
        state.gridApi3.showNoRowsOverlay();
      }
      if (results[1]?.rows.length > 0) {
      //  state.gridApi2.hideOverlay();
        state.gridApi2.setGridOption("loading", false);
      } else {
        state.gridApi2.setGridOption("loading", false);
        state.gridApi2.showNoRowsOverlay();
      }
    } catch (err) {
      console.log(err);
      setState((p) => ({
        ...p,
        alertWarning: "Failed to load grid data.",
      }));
    }
  };

  const formatInboundData = (data) => {
    let rows = data.sort((a, b) => {
      if (!a.projectName) a.projectName = "";
      if (!a.projectId) a.projectId = "";
      if (!b.projectName) b.projectName = "";
      if (!b.projectId) b.projectId = "";
      return (
        a.projectName.localeCompare(b.projectName) || a.projectId - b.projectId
      );
    });

    let count = 0;
    for (let x = 0; x < rows.length; x++) {
      let row = rows[x];
      let arr = [...rows];
      arr = arr.filter((obj) => {
        return (
          obj.projectId === row.projectId && obj.projectName === row.projectName
        );
      });
      console.log("yo this is your count =>", count);
      if (count === 0) {
        count = arr.length;
        row.count = count;
        row.max = true;
        count = count - 1;
        continue;
      }
      row.max = false;
      row.count = count;
      count = count - 1;
    }
    return rows;
  };

  const transposeData = async (rows, type) => {
    let columns = type === 1 ? col1 : col2;
    let count = 0;
    let data = await Promise.all(
      Object.values(
        rows.reduce((a, obj) => {
          count = count + 1;
          for (const [label, val] of Object.entries(obj)) {
            let skipValue =
              localStorage.getItem("userType") === "vendor"
                ? "vendor"
                : "client";
            if (label === skipValue) {
              //skipping based on vendor or client
              continue;
            }
            let { name, value } = formatNameValues(label, val, columns);
            if (name === "") {
              //skipping columns that were not found in the switch
              continue;
            }
            if (name === "Images Expected") {
              if (!a["break1"]) a["break1"] = { name: "" };
              a["break1"][`value${count}`] = "";
              if (!a["barcodeCount"]) a["barcodeCount"] = { name: "" };
              a["barcodeCount"][`value${count}`] = "Barcodes Count";
            }
            if (name === "Diagnosis Code Not Received") {
              // if (!a["break2"]) a["break2"] = { name: "" };
              // a["break2"][`value${count}`] = "";
              // if (!a["diagnosisCount"]) a["diagnosisCount"] = { name: "" };
              // a["diagnosisCount"][`value${count}`] = "Diagnosis Code Count";
            }
            if (!a[name]) a[name] = { name };
            a[name][`value${count}`] = value;
          }

          return a;
        }, {})
      )
    );

    let order = await getSortOrder(type);

    data.sort(function (a, b) {
      return order[a.name] - order[b.name];
    });

    await setColumnDefs(count, type);
    return data;
  };

  const getSortOrder = (type) => {
    let columns;
    if (type === 1) {
      columns = [
        "Project Name",
        "Project ID",
        "Segment",
        "CV/DOS Year",
        // "Vendor Name",
        "Vendor Code",
        "Client Name",
        "Images Expected",
        "Images Received",
        "Diagnosis Code Not Received",
        "Diagnosis Code Received",
        "Diagnosis Code Canceled",
      ];
    } else {
      columns = [
        "Project Name",
        "Project ID",
        "Vendor Name",
        "Client Name",
        "Diagnosis Code Records Received",
        "Successful Transactions",
        "Duplicate Transactions",
        "Errored Transactions",
        "Waiting to Process Transactions",
      ];
    }
    let order = {};
    for (let x = 0; x < columns.length; x++) {
      order[columns[x]] = x + 1;
    }
    return order;
  };

  const formatNameValues = (label, val, columns) => {
    let name = "";
    let value = "";
    if (columns.includes(label)) {
      switch (label) {
        case "projectName":
          name = "Project Name";
          value = val;
          break;
        case "projectId":
          name = "Project ID";
          value = val;
          break;
        case "businessSegment":
          name = "Segment";
          value = val;
          break;
        case "dosYear":
          name = "CV/DOS Year";
          value = val;
          break;
        // case "vendor":
        //   name = "Vendor Name";
        //   value = val;
        //   break;
        case "vendorCode":
          name = "Vendor Code";
          value = val;
          break;
        // case "hpCd":
        //   name = "HPCD";
        //   value = val;
        //   break;
        case "client":
          name = "Client Name";
          value = val;
          break;
        case "imagesExpected":
          name = "Images Expected";
          value = formatNumberWithComma(val);
          break;
        case "imagesReceived":
          name = "Images Received";
          value = formatNumberWithComma(val);
          break;
        case "dxNotReceived":
          name = "Diagnosis Code Not Received";
          value = formatNumberWithComma(val);
          break;
        case "dxReceived":
          name = "Diagnosis Code Received";
          value = formatNumberWithComma(val);
          break;
        case "dxCodeCount":
          name = "Diagnosis Code Records Received";
          value = formatNumberWithComma(val);
          break;
        case "dxCanceled":
          name = "Diagnosis Code Canceled";
          value = formatNumberWithComma(val);
          break;
        case "successfulTransaction":
          name = "Successful Transactions";
          value = formatNumberWithComma(val);
          break;
        case "duplicatedTransaction":
          name = "Duplicate Transactions";
          value = formatNumberWithComma(val);
          break;
        case "erroredTransaction":
          name = "Errored Transactions";
          value = formatNumberWithComma(val);
          break;
        case "waitingToProcessTransaction":
          name = "Waiting to Process Transactions";
          value = formatNumberWithComma(val);
          break;
        default:
          break;
      }
    }
    return { name, value };
  };

  const setColumnDefs = async (length, type) => {
    let columnsDefs = [
      {
        colId: "name",
        field: "name",
        width: 250,
        cellClass: "alignLeft",
      },
    ];
    for (let x = 0; x < length; x++) {
      columnsDefs.push({
        colId: `value${x + 1}`,
        field: `value${x + 1}`,
        width: 250,
        cellClass: "alignLeft",
      });
    }

    if (type === 1) {
      await setState((p) => ({
        ...p,
        columnDefs1: columnsDefs,
      }));
    } else {
      await setState((p) => ({
        ...p,
        columnDefs3: columnsDefs,
      }));
    }
  };

  const onChangeDropdown = (name, val) => {
    let value = val;
    if (name === "projectName" || name === "projectId") {
      if (val.length > 1) {
        let lastItem = [...val].pop();
        if (lastItem === "All") {
          value = ["All"];
        } else {
          value = value.filter((val) => {
            return val != "All";
          });
        }
      }
    }
    setState((s) => ({ ...s, [name]: value }));
  };

  const onCloseAlertWarning = () => {
    setState((p) => ({
      ...p,
      alertWarning: null,
    }));
  };

  const onCloseAlertInfo = () => {
    setState((p) => ({
      ...p,
      alertInfo: null,
    }));
  };

  const handleClear = (e) => {
    let dosYear = "";
    if (state.dosYearOptions) {
      const lastYear = moment().year() - 1;
      dosYear = lastYear.toString();
    }
    setState((p) => ({
      ...p,
      dosYear: dosYear,
      projectId: ["All"],
      segment: "ACA",
      projectName: ["All"],
      vendorName:
        localStorage.getItem("userType") === "vendor"
          ? [localStorage.getItem("vendorName")]
          : [],
      clientName:
        localStorage.getItem("userType") !== "vendor"
          ? [localStorage.getItem("clientName")]
          : [],
    }));
  };

  const DateAndTime = () => {
    return new Date().toLocaleString();
  };

  const handleExport = async (file, type) => {
    console.log("file =>", file);
    console.log("type =>", type);
    let visibleColumns1 = state.gridApi1.getAllDisplayedColumns();
    let visibleColumns2 = state.gridApi2.getAllDisplayedColumns();
    let visibleColumns3 = state.gridApi3.getAllDisplayedColumns();

    console.log("visibleColumns1 => ", visibleColumns1);
    console.log("visibleColumns2 => ", visibleColumns2);
    console.log("visibleColumns3 => ", visibleColumns3);

    let grid1Columns = [];
    let grid2Columns = [];
    let grid3Columns = [];

    visibleColumns1.forEach((col) => {
      grid1Columns.push(col.colId);
    });

    visibleColumns2.forEach((col) => {
      grid2Columns.push(col.colId);
    });

    visibleColumns3.forEach((col) => {
      grid3Columns.push(col.colId);
    });

    let fileName = "";

    if (file === "all") {
      fileName = "Chart3PSummary";
    } else if (file === "processSummaryReporting") {
      fileName = "ProcessSummaryReporting";
    } else if (file === "dxInboundFileDetails") {
      fileName = "DXInboundFileDetails";
    } else if (file === "chartCodingAddsReporting") {
      fileName = "ChartCodingAddsReporting";
    }

    if (type === "pdf") {
      let grids = [];
      if (file === "processSummaryReporting" || file === "all") {
        grids.push({
          gridApi: state.gridApi1,
          header: "Process Summary Reporting",
          columnIds: grid1Columns,
        });
      }
      if (file === "dxInboundFileDetails" || file === "all") {
        grids.push({
          gridApi: state.gridApi2,
          header: "Diagnosis Code Inbound File Details",
          columnIds: grid2Columns,
        });
      }
      if (file === "chartCodingAddsReporting" || file === "all") {
        grids.push({
          gridApi: state.gridApi3,
          header: "Chart Coding Adds Reporting",
          columnIds: grid3Columns,
        });
      }
      printDoc(grids, `${fileName}_${moment().format("YYYYMMDDHHmm")}`);
    } else if (type === "excel") {
      let title = "";
      let fileTitle = "";
      if (file === "all") {
        title = "All Chart3P Summary Reports";
        fileTitle = "Chart3PSummary";
      } else if (file === "processSummaryReporting") {
        title = "Process Summary Reporting";
        fileTitle = "ProcessSummaryReporting";
      } else if (file === "dxInboundFileDetails") {
        title = "Diagnosis Code Inbound File Details";
        fileTitle = "DXInboundFileDetails";
      } else if (file === "chartCodingAddsReporting") {
        title = "Chart Coding Adds Reporting";
        fileTitle = "ChartCodingAddsReporting";
      }
      let header = [
        {
          cells: [
            // Row 1:
            {
              data: {
                type: "String",
                value: Logos.hdrOptum2, // see logos.js
              },
              mergeAcross: 1,
            },
          ],
        },
        { cells: [] }, // Row 2
        {
          cells: [
            // Row 3
            {
              styleId: "bigHeader",
              data: {
                type: "String",
                value: title,
              },
              mergeAcross: 1,
            },
          ],
        },
        {
          cells: [
            // Row 4
            {
              data: {
                type: "String",
                value: "Report Date: " + DateAndTime(),
              },
              mergeAcross: 2,
            },
          ],
        },
        { cells: [] }, // Row 5
      ];

      let footer = [
        { cells: [] },
        {
          cells: [
            {
              styleId: "smallFooter",
              data: {
                type: "String",
                value: "End of Report",
              },
            },
          ],
        },
      ];

      let spreadsheets = [];

      if (file === "processSummaryReporting" || file === "all") {
        spreadsheets.push(
          state.gridApi1.getSheetDataForExcel({
            sheetName: "Process Summary Reporting",
            prependContent: header,
            appendContent: footer,
            skipColumnHeaders: true,
            rowHeight: (params) => (params.rowIndex === 1 ? 70 : 25),
            addImageToCell: (rowIndex, col, value) => {
              if (rowIndex !== 1) {
                return;
              }
              return {
                image: {
                  id: "logo",
                  base64: value,
                  imageType: "png",
                  width: 197,
                  height: 67,
                  position: {
                    colSpan: 2,
                  },
                },
              };
            },
            columnKeys: grid1Columns,
          })
        );
      }

      if (file === "dxInboundFileDetails" || file === "all") {
        spreadsheets.push(
          state.gridApi2.getSheetDataForExcel({
            sheetName: "Diagnosis Code Inbound File Details",
            prependContent: header,
            appendContent: footer,
            rowHeight: (params) => (params.rowIndex === 1 ? 70 : 25),
            addImageToCell: (rowIndex, col, value) => {
              if (rowIndex !== 1) {
                return;
              }
              return {
                image: {
                  id: "logo",
                  base64: value,
                  imageType: "png",
                  width: 197,
                  height: 67,
                  position: {
                    colSpan: 2,
                  },
                },
              };
            },
            columnKeys: grid2Columns,
          })
        );
      }

      if (file === "chartCodingAddsReporting" || file === "all") {
        spreadsheets.push(
          state.gridApi3.getSheetDataForExcel({
            sheetName: "Chart Coding Adds Reporting",
            prependContent: header,
            appendContent: footer,
            skipColumnHeaders: true,
            rowHeight: (params) => (params.rowIndex === 1 ? 70 : 25),
            addImageToCell: (rowIndex, col, value) => {
              if (rowIndex !== 1) {
                return;
              }
              return {
                image: {
                  id: "logo",
                  base64: value,
                  imageType: "png",
                  width: 197,
                  height: 67,
                  position: {
                    colSpan: 2,
                  },
                },
              };
            },
            columnKeys: grid3Columns,
          })
        );
      }

      // Adjust this to export multiple grids to Excel
      const blob = getMultipleSheetsAsExcel({
        author: "Optum Chart3P",
        data: spreadsheets,
        fileName: `${fileName}_${moment().format("YYYYMMDDHHmm")}.xlsx`,
        fontsize: 11,
      });

      await blobToFile({
        blob,
        fileName: `${fileName}_${moment().format("YYYYMMDDHHmm")}.xlsx`,
        fileType: "xlsx",
      });

      // await unsubscribe("handleExport", () => setExportFinished(true));
    }
  };

  const blobToFile = async (arg) => {
    const { blob, fileName, fileType } = arg;
    let options = {
      suggestedName: `${fileName}`,
    };
    await window
      .showSaveFilePicker(options)
      .then(async (fileHandle) => await fileHandle.createWritable())
      .then(async (stream) => {
        await stream.write(blob);
        await stream.close();
      })
      .then(() => {
        alert(`File ${fileName} has been successfully downloaded.`);
      })
      .catch((err) => {
        console.log(err);
        if (err.toString().includes("The user aborted a request")) {
          console.log("SaveFile aborted");
          alert("File download has been canceled.");
        } else {
          alert("There was an issue downloading the file, please try again.");
        }
      });
  };

  const handleOpenExportMenu = async (e) => {
    await setState((s) => ({
      ...s,
      anchorEl: e.currentTarget,
      exportMenuOpen: !s.exportMenuOpen,
    }));
  };

  const handleCloseExportMenu = async () => {
    await setState((s) => ({
      ...s,
      anchorEl: null,
      exportMenuOpen: false,
    }));
  };

  const gridOptions1 = {
    getRowStyle: (params) => {
      const bg = "#D9F6FA"; // Light blue
      const fc = "#002677"; // Dark blue
      if (params.node.data.name === "") return { border: "none" };
      if (
        params.node.data.name === "Project Name" ||
        params.node.data.name === "Project ID"
      ) {
        return { background: bg, fontWeight: "bold", color: fc };
      } else {
        return;
      }
    },
    getRowHeight: (params) => {
      return (window.innerWidth * 1.5) / 100;
    },
  };

  const defaultColDef1 = {
    cellStyle: (params) => {
      const gray = "#ccc";
      let moreStyles = {};
      if (params.node.data.name === "")
        return { border: "none", borderTop: "none", backgroundColor: "white" };
      if (params.column.colDef.colId === "name")
        moreStyles.borderLeft = `1px solid ${gray}`;
      return {
        borderTop: `1px solid ${gray}`,
        borderRight: `1px solid ${gray}`,
        ...moreStyles,
      };
    },
  };

  const defaultColDef2 = {
    suppressHeaderFilterButton: true,
    cellStyle: (params) => {
      // let moreStyles = {
      //   borderBottom:
      //     "var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width)",
      // };
      if (
        (params.column.colDef.colId === "projectName" ||
          params.column.colDef.colId === "projectId") &&
        params.node.data.count > 0
      ) {
        if (params.node.data.max === true && params.node.data.count > 1) {
          return { backgroundColor: "#D9F6FA", color: "black" };
        } else if (
          params.node.data.max === false &&
          params.node.data.count > 1
        ) {
          return { backgroundColor: "#D9F6FA", color: "#D9F6FA" };
        } else if (
          params.node.data.max === false &&
          params.node.data.count == 1
        ) {
          return {
            backgroundColor: "#D9F6FA",
            color: "#D9F6FA",
            borderBottom:
              "var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width)",
          };
        } else if (
          params.node.data.max === true &&
          params.node.data.count === 1
        ) {
          return {
            borderBottom:
              "var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width)",
          };
        } else {
          return {
            backgroundColor: "#D9F6FA",
            color: "#D9F6FA",
            borderBottom:
              "var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width)",
          };
        }
      } else {
        return {
          borderBottom:
            "var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width)",
        };
      }
    },
  };

  const gridOptions2 = {
    suppressRowTransform: true,
    getRowHeight: (params) => {
      return (window.innerWidth * 1.5) / 100;
    },
    getRowStyle: (params) => {
      if (params.node.data.count > 0) {
        if (params.node.data.max === true && params.node.data.count > 1) {
          console.log("heres 1 =>", params.node.data);
          return {
            border: "none",
          };
        } else if (
          params.node.data.max === true &&
          params.node.data.count === 1
        ) {
          console.log("heres 2 =>", params.node.data);
          return {
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none",
          };
        } else if (
          params.node.data.max === false &&
          params.node.data.count === 1
        ) {
          console.log("heres 3 =>", params.node.data);
          return {
            borderLeft: "none",
            borderRight: "none",
            borderTop: "none",
            borderBottom: "none",
          };
        } else {
          console.log("heres 4 =>", params.node.data);
          return { border: "none" };
        }
      } else {
        console.log("heres 5 =>", params.node.data);
        return { border: "none" };
      }
    },
  };

  const gridOptions3 = {
    getRowStyle: (params) => {
      const bg = "#D9F6FA"; // Light blue
      const fc = "#002677"; // Dark blue
      if (params.node.data.name === "") return { border: "none" };
      if (
        params.node.data.name === "Project Name" ||
        params.node.data.name === "Project ID"
      ) {
        return { background: bg, fontWeight: "bold", color: fc };
      } else {
        return;
      }
    },
    getRowHeight: (params) => {
      return (window.innerWidth * 1.5) / 100;
    },
  };

  return (
    <Container
      fluid
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        width: "98%",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <img
        src={`${localStorage.getItem(
          "apiUrl"
        )}/getHit?c=Dashboard&u=${localStorage.getItem("u")}
          &name=${localStorage.getItem("displayName")}
          &utype=${localStorage.getItem("userType")}
          &vendor=${localStorage.getItem("vendorName")}
          &client=${localStorage.getItem("clientName")}`}
        alt=""
        style={{ display: "none" }}
      />
      <Snackbar
        open={state.alertWarning !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={onCloseAlertWarning}
      >
        <Alert
          variant="filled"
          onClose={onCloseAlertWarning}
          severity="warning"
        >
          {state.alertWarning}
        </Alert>
      </Snackbar>

      <Snackbar
        open={state.alertInfo !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={onCloseAlertInfo}
      >
        <Alert variant="filled" onClose={onCloseAlertInfo} severity="info">
          {state.alertInfo}
        </Alert>
      </Snackbar>
      <Row>
        <Col xs={2} style={{ height: "91vh" }}>
          <div
            style={{
              padding: 5,
              border: "2px solid #e77721",
              borderRadius: 30,
              display: "flex",
              flexFlow: "column nowrap",
              height: "100%",
              // backgroundColor: "green",
            }}
          >
            <div
              style={{
                height: "3%",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  marginBottom: 8,
                  color: "#e77721",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Search Criteria
              </Typography>
            </div>
            <div
              style={{
                overflow: "auto",
                marginTop: "2%",
                flexGrow: "1",
                // backgroundColor: "blue",
              }}
            >
              <Autocomplete
                options={state.segmentOptions}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={state.segment}
                onChange={(e, val) => onChangeDropdown("segment", val)}
                noOptionsText={"Select Segment"}
                id="segment"
                style={{ width: "100%", marginTop: "2%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Segment" required />
                )}
              />
              <Autocomplete
                options={state.dosYearOptions}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={state.dosYear}
                onChange={(e, val) => onChangeDropdown("dosYear", val)}
                noOptionsText={"Select Year"}
                id="dosYear"
                style={{ width: "100%", marginTop: "10%" }}
                renderInput={(params) => (
                  <TextField {...params} label="CV/DOS Year" required />
                )}
              />
              <Autocomplete
                multiple
                options={["All", ...state.projectNameOptions]}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={state.projectName}
                onChange={(e, val) => onChangeDropdown("projectName", val)}
                noOptionsText={"Select Project Name"}
                id="projectName"
                style={{ width: "100%", marginTop: "10%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Project Name" />
                )}
              />
              <Autocomplete
                multiple
                options={["All", ...state.projectIdOptions]}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={state.projectId}
                onChange={(e, val) => onChangeDropdown("projectId", val)}
                noOptionsText={"Select Project ID"}
                id="projectId"
                style={{ width: "100%", marginTop: "10%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Project ID" />
                )}
              />
              {state.userType !== "vendor" ? (
                <Autocomplete
                  multiple
                  options={state.vendorOptions}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={state.vendorName}
                  onChange={(e, val) => onChangeDropdown("vendorName", val)}
                  style={{ width: "100%", marginTop: "10%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Vendor Name" />
                  )}
                />
              ) : (
                <Autocomplete
                  multiple
                  options={state.clientOptions}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={state.clientName}
                  onChange={(e, val) => onChangeDropdown("clientName", val)}
                  style={{ width: "100%", marginTop: "10%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Client Name" />
                  )}
                />
              )}
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginLeft: 0,
                // backgroundColor: "red",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                title="Recalculate the Data"
                style={{
                  marginRight: "2%",
                }}
                onClick={getGridData}
              >
                Recalculate
              </Button>
              <Menu
                anchorEl={state.anchorEl}
                open={state.exportMenuOpen}
                onClose={handleCloseExportMenu}
                getcontentanchorel={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    minWidth: "300px",
                    maxHeight: "100px",
                  },
                }}
              >
                <ContextMenu
                  items={state.exportItems}
                  onExportClick={handleExport}
                ></ContextMenu>
              </Menu>
              <Button
                variant="contained"
                color="secondary"
                title="Export to Excel or PDF"
                style={{
                  marginLeft: "2%",
                }}
                onClick={handleOpenExportMenu}
              >
                Export
              </Button>
              <Button
                variant="contained"
                color="secondary"
                title="Reset the Search Criteria"
                style={{
                  margin: "2%",
                  backgroundColor: "white",
                  color: "black",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                onClick={handleClear}
              >
                Reset
              </Button>
            </div>
          </div>
        </Col>
        <Col xs={10} style={{ overflowY: "auto", height: "91vh" }}>
          <div ref={divRef}>
            <Row className="m-0 p-0">
              <Typography
                variant="h6"
                style={{
                  marginBottom: "1%",
                  color: "#e77721",
                  fontWeight: "bold",
                }}
              >
                Process Summary Reporting
              </Typography>
              <Container
                fluid
                className="ag-theme-balham"
                style={{
                  // height: "36vh",
                  width: "100%",
                }}
                id="uniqueAGGridID1"
              >
                <AgGridReact
                  ref={gridRef1}
                  onGridReady={onGridReady1}
                //  rowSelection="single"
                  selection={{mode:"singleRow", checkboxes:false, enableClickSelection:true}}
                  domLayout="autoHeight"
                  columnDefs={state.columnDefs1}
                  rowData={state.rowData1}
                  components={state.frameworkComponents}
                  loadingOverlayComponent={state.loadingOverlayComponent}
                  noRowsOverlayComponent={state.noRowsOverlayComponent}
                  animateRows={true}
                  gridOptions={gridOptions1}
                  defaultColDef={defaultColDef1}
                  excelStyles={excelStyles}
                ></AgGridReact>
              </Container>
            </Row>
            <Row className="m-0 p-0">
              <Typography
                variant="h6"
                style={{
                  marginTop: "1%",
                  marginBottom: "1%",
                  color: "#e77721",
                  fontWeight: "bold",
                }}
              >
                Diagnosis Code Inbound File Details
              </Typography>
              <Container
                fluid
                className="ag-theme-balham"
                style={{
                  height: "18vh",
                  width: "100%",
                }}
              >
                <AgGridReact
                  ref={gridRef2}
                  onGridReady={onGridReady2}
                //  rowSelection="single"
                  selection={{mode:"singleRow", checkboxes:false, enableClickSelection:true}}
                  // pagination={true}
                  domLayout="normal"
                  headerHeight={state.headerHeight}
                  groupHeaderHeight={state.headerHeight}
                  columnDefs={state.columnDefs2}
                  rowData={state.rowData2}
                  components={state.frameworkComponents}
                  loadingOverlayComponent={state.loadingOverlayComponent}
                  noRowsOverlayComponent={state.noRowsOverlayComponent}
                  animateRows={true}
                  defaultColDef={defaultColDef2}
                  gridOptions={gridOptions2}
                  excelStyles={excelStyles}
                ></AgGridReact>
              </Container>
            </Row>
            <Row className="m-0 p-0">
              <Typography
                variant="h6"
                style={{
                  marginTop: "1%",
                  marginBottom: "1%",
                  color: "#e77721",
                  fontWeight: "bold",
                }}
              >
                Chart Coding Adds Reporting
              </Typography>
              <Container
                fluid
                className="ag-theme-balham"
                style={{
                  height: "300px !important",
                  width: "100%",
                }}
                id="uniqueAGGridID2"
              >
                <AgGridReact
                  ref={gridRef3}
                  onGridReady={onGridReady3}
                //  rowSelection="single"
                  selection={{mode:"singleRow", checkboxes:false, enableClickSelection:true}}
                  domLayout="autoHeight"
                  columnDefs={state.columnDefs3}
                  rowData={state.rowData3}
                  components={state.frameworkComponents}
                  loadingOverlayComponent={state.loadingOverlayComponent}
                  noRowsOverlayComponent={state.noRowsOverlayComponent}
                  animateRows={true}
                  gridOptions={gridOptions3}
                  defaultColDef={defaultColDef1}
                  excelStyles={excelStyles}
                ></AgGridReact>
              </Container>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
